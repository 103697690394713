var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ProjectIntroductionDetails"},[_c('heade'),_c('div',{staticClass:"cen_wrap"},[_c('div',{staticClass:"cen_box"},[_c('div',{staticClass:"cen_left"},[_c('div',{staticClass:"cen_left_title"},[_vm._v(" "+_vm._s(_vm.KeyProjects.name)+" ")]),_c('div',{staticClass:"cen_left_con"},_vm._l((_vm.KeyProjectChildrens),function(item,index){return _c('div',{key:index},[(item.children.length == 0)?_c('div',{directives:[{name:"trigger",rawName:"v-trigger"}],class:_vm.queryWhere.id == item.id ? 'addclass' : '',attrs:{"id":'trigger' + index},on:{"click":function($event){return _vm.getKeyProject(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('div',{class:_vm.queryWhere.id == item.id ? 'addclass' : '',attrs:{"id":item.id},on:{"click":function($event){return _vm.getKeyProjects(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])])}),0)]),_c('div',{staticClass:"cen_right"},[(_vm.content == '')?_c('div',[_c('div',{staticClass:"k"},[_c('div',{staticClass:"kdiv"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.articleh.content)}})]),_c('div',{staticClass:"kdivaa"},[_c('div',{staticClass:"kdiva"},_vm._l((_vm.children),function(items,indexs){return _c('div',{key:indexs},[_c('router-link',{staticClass:"keys",attrs:{"to":{
                    path: '/project/detail',
                    query: { id: items.id },
                  }}},[_vm._v(_vm._s(items.name))])],1)}),0),_c('div',{staticStyle:{"font-size":"16px","text-align":"center"}},[_c('router-link',{attrs:{"to":{
                  path: _vm.articleha.link_url,
                }}},[_vm._v(_vm._s(_vm.articleha.name))])],1),_c('div',{staticStyle:{"font-size":"16px","text-align":"center","margin-top":"10px"}},[_vm._v(" "+_vm._s(_vm.articlehas.name)+" ")])])])]):_vm._e(),(_vm.content != '')?_c('div',{staticClass:"key_project_play"},[(_vm.options.source != '' && _vm.queryWhere.id == 25)?_c('div',{staticClass:"prism-player-box"},[_c('vue-aliplayer-v2',{ref:"VueAliplayerV2",attrs:{"options":_vm.options}})],1):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}})]):_vm._e()])]),_c('div',{staticClass:"link_box"},[_c('div',{staticClass:"link"},[_c('router-link',{staticClass:"add",attrs:{"to":"/project"}},[_vm._v("课题研究介绍")])],1),_vm._l((_vm.goodsCatgegories),function(item,index){return _c('div',{key:index,staticClass:"link"},[_c('router-link',{attrs:{"to":{
          path: '/goods/goods',
          query: { id: item.id },
        }}},[_vm._v(_vm._s(item.name))])],1)}),_c('div',{staticClass:"link"},[_c('router-link',{attrs:{"to":"/article/primaryEquip"}},[_vm._v("人教版全国中小学传统文化教学一体机")])],1),_c('div',{staticClass:"link"},[_c('router-link',{attrs:{"to":"/article/campusServer"}},[_vm._v("中小学传统文化数字资源研究传播中心配套校园终端服务器 ")])],1)],2)]),_c('foot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }